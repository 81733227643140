import { Card, Col, Row } from 'react-bootstrap';
import cona from '../images/con1.jpg';
import conb from '../images/con2.jpg';
import conc from '../images/con3.jpg';
import cond from '../images/con4.jpg';
import cone from '../images/con5.jpg';
import './css/Consejo.css';
// Impotaciones de menu
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet, Link } from "react-router-dom"
import logoss from '../images/logos.jpg'
import Flip from 'react-reveal/Flip';
//Importaciones de footer
import Button from 'react-bootstrap/Button';

import React from 'react';
import SocialMediaLinks from './iconos';


function WithHeaderExample() {
  return (

<section>

<Navbar collapseOnSelect expand="lg" className="bg-transparent">
      <Container>
      <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logoss} width="80" height="auto" /></Flip></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">    
                <Nav.Link as={Link} to="/" className='navLinkStyle'>Home</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>Blog</Nav.Link>    
                <Nav.Link as={Link} to="/Cardst" className='navLinkStyle'>Talleres y Cursos</Nav.Link> 
                <Nav.Link as={Link} to="/Articulos" className='navLinkStyle'>Artículos de Opinión</Nav.Link>  
                <Nav.Link as={Link} to="/Proyectos" className='navLinkStyle'>Proyectos</Nav.Link>  
                <Nav.Link as={Link} to="/Contacto" >Contacto</Nav.Link>
                <Nav.Link as={Link} to="/Consejo" className='navLinkStyle'>Consejo Asesor</Nav.Link>                   
            </Nav>
            <Nav className="ms-auto"> 
            <SocialMediaLinks />            
          </Nav>
        </Navbar.Collapse>
        

      </Container>
    </Navbar>
    <section>
            <Outlet>


              
            </Outlet>
        </section> 
{/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

    <Card style={{ margin: '20px', backgroundColor: '#f8f9fa' }}>
    <Row noGutters>
      {/* Columna izquierda para la imagen */}
      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center">
        <Card.Img
          src={cona}
          alt="Card Image"
          className="rounded-circle img-fluid"
          style={{ objectFit: 'cover', width: '40%', height: 'auto' }}
        />
      </Col>
      {/* Columna derecha para el texto */}

      <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex align-items-center">
      <Card.Body className="p-sm-3 p-md-4 p-lg-5 p-xl-5">
          <Card.Title>Graciela Teruel Belismelis</Card.Title>
          <Card.Text className="mb-0">
            <h4 className='cardtex'>Licenciada en Economía por el Instituto Tecnológico Autónomo de México y maestra y doctora en Economía por la Universidad de California Los Angeles. Desde que recibió su doctorado ha participado activamente en la evaluación de impacto de programas sociales, entre los que se encuentran: Progresa (componente rural), Oportunidades (componente rural y urbano) y Proyectos Productivos. Actualmente es directora del Instituto de Investigación para el Desarrollo con Equidad (EQUIDE) y Profesor-Investigador de tiempo completo en la Universidad Iberoamericana A.C. Es miembro del Comité Técnico para la Medición Oficial de la Pobreza en México y miembro del Sistema Nacional de Investigadores. Es miembro académico del Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL), miembro académico del Consejo Consultivo para el Aprovechamiento de la Energía Sustentable y miembro del Sistema Nacional de ́ Investigadores (SNI), nivel III.</h4>
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>

{/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

  <Card style={{ margin: '20px', backgroundColor: '#f8f9fa' }}>
    <Row noGutters>
      {/* Columna izquierda para la imagen */}
      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center">
        <Card.Img
          src={conb}
          alt="Card Image"
          className="rounded-circle img-fluid"
          style={{ objectFit: 'cover', width: '40%', height: 'auto' }}
        />
      </Col>
      {/* Columna derecha para el texto */}

      <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex align-items-center">
      <Card.Body className="p-sm-3 p-md-4 p-lg-5 p-xl-5">
          <Card.Title>César Velázquez Guadarrama</Card.Title>
          <Card.Text className="mb-0">
            <h4 className='cardtex'>Cuenta con un doctorado en  en Políticas Públicas de la  University of Chicago, EEUU. Sus áreas de especialización son economía del sector público, finanzas públicas estatales y municipales, evaluación de programas sociales y competencia y regulación. Actualmente es profesor investigador de la Universidad Iberoamericana y coordinador de la maestría en Políticas Públicas por la misma universidad. ​​</h4>
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>

{/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
<Card style={{ margin: '20px', backgroundColor: '#f8f9fa' }}>
    <Row noGutters>
      {/* Columna izquierda para la imagen */}
      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center">
        <Card.Img
          src={conc}
          alt="Card Image"
          className="rounded-circle img-fluid"
          style={{ objectFit: 'cover', width: '40%', height: 'auto',
          filter: 'grayscale(100%)', // Aplica el filtro blanco y negro

        }}
        />
      </Col>
      {/* Columna derecha para el texto */}

      <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex align-items-center">
      <Card.Body className="p-sm-3 p-md-4 p-lg-5 p-xl-5">
          <Card.Title>Mariana Díaz Figueroa</Card.Title>
          <Card.Text className="mb-0">
            <h4 className='cardtex'>Cuenta na amplia experiencia en derechos humanos y género. Fue elegida por la revista Foro Jurídico como una de las “abogadas influyentes de México 2019. Premio INEGI 2018 en la categoría de Información en plataformas digitales, por el artículo “El derecho al trabajo de las personas con discapacidad, elemento necesario para su efectiva inclusión social”, México 2017, Impunidad Cero, Revista Este País. Maestra en Derecho Humanos y Democracia: Con Mención en Protección Internacional de los Derechos Humanos. (Aprobada con grado de excelencia); Maestra en Derecho Electora (lInstituto Prisciliano Sánchez (Tribunal Electoral del Estado de Jalisco) y Licenciada en Derecho (mención honorífica) Universidad Ibero Americana ​​</h4>
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>

  {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
<Card style={{ margin: '20px', backgroundColor: '#f8f9fa' }}>
    <Row noGutters>
      {/* Columna izquierda para la imagen */}
      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center">
        <Card.Img
          src={cond}
          alt="Card Image"
          className="rounded-circle img-fluid"
          style={{ objectFit: 'cover', width: '40%', height: 'auto',
          filter: 'grayscale(100%)', // Aplica el filtro blanco y negro
        }}
        />
      </Col>
      {/* Columna derecha para el texto */}

      <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex align-items-center">
      <Card.Body className="p-sm-3 p-md-4 p-lg-5 p-xl-5">
          <Card.Title>Alil Álvarez Alcalá</Card.Title>
          <Card.Text className="mb-0">
            <h4 className='cardtex'>Alil es abogada, cuenta con una Maestría en Derecho por la Universidad de Stanford y un Doctorado en Derecho por el Instituto de Investigaciones Jurídicas de la Universidad Nacional Autónoma de México (UNAM). Alil cuenta con amplia experiencia y alto grado de especialización en materia tributaria, además de contar con más de veinte años de experiencia asesorando integralmente a artistas y deportistas. Forma parte de The Society of Trust and Estate Practitioners, Miami (STEP Miami). Como académica, Alil se ha desempeñado como profesora de Derecho Fiscal. Adicionalmente, ha impartido múltiples conferencias en diversos foros nacionales como la Feria Internacional del Libro de Guadalajara (2014), el Centro de Investigación y Docencia Económica (CIDE), el Instituto de Investigaciones Jurídicas de la UNAM, la Asociación Nacional de Abogados de Empresa (ANADE), así como en diversos foros internacionales como la Universidad Torcuato di Tella en Argentina, Stanford University y el International Fiscal Associati​</h4>
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>

    {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
<Card style={{ margin: '20px', backgroundColor: '#f8f9fa' }}>
    <Row noGutters>
      {/* Columna izquierda para la imagen */}
      <Col xs={12} sm={4} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center">
        <Card.Img
          src={cone}
          alt="Card Image"
          className="rounded-circle img-fluid"
          style={{ objectFit: 'cover', width: '40%', height: 'auto',
          filter: 'grayscale(100%)', // Aplica el filtro blanco y negro

        }}
          
        />
      </Col>
      {/* Columna derecha para el texto */}

      <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex align-items-center">
      <Card.Body className="p-sm-3 p-md-4 p-lg-5 p-xl-5">
          <Card.Title>Alil Álvarez Alcalá</Card.Title>
          <Card.Text className="mb-0">
            <h4 className='cardtex'>Alil es abogada, cuenta con una Maestría en Derecho por la Universidad de Stanford y un Doctorado en Derecho por el Instituto de Investigaciones Jurídicas de la Universidad Nacional Autónoma de México (UNAM). Alil cuenta con amplia experiencia y alto grado de especialización en materia tributaria, además de contar con más de veinte años de experiencia asesorando integralmente a artistas y deportistas. Forma parte de The Society of Trust and Estate Practitioners, Miami (STEP Miami). Como académica, Alil se ha desempeñado como profesora de Derecho Fiscal. Adicionalmente, ha impartido múltiples conferencias en diversos foros nacionales como la Feria Internacional del Libro de Guadalajara (2014), el Centro de Investigación y Docencia Económica (CIDE), el Instituto de Investigaciones Jurídicas de la UNAM, la Asociación Nacional de Abogados de Empresa (ANADE), así como en diversos foros internacionales como la Universidad Torcuato di Tella en Argentina, Stanford University y el International Fiscal Associat​</h4>
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>
    {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

    <section>
    <div className="footerr">
    <Container>
      <Row className='row2'>
        <Col  md={3}>
          <img src={logoss} width="100px" alt="" />
        </Col>
        <Col  md={3}>
         <div>
          <h2 className='h2h'>A PROPÓSITO</h2>
          <p>Fomentamos la igualdad de género, el empoderamiento, la no discriminación y la erradicación de la violencia hacia las mujeres </p>
         </div>
        </Col>
        <Col md={3}>
        <div className='rowesp'>
          <h2 className='h2h'>A EXPLORAR</h2>
          <ul className="listafooter">
          <i className='mx-auto'>
                <li><Nav.Link as={Link} to="/" className='navfo'>Home</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Blog" className='navfo'>Blog</Nav.Link></li>    
                <li><Nav.Link as={Link} to="/Cardst" className='navfo'>Talleres y Cursos</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Articulos" className='navfo'>Artículos de Opinión</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Contacto" className='navfo'>Contacto</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Consejo" className='navfo'>Consejo Asesor</Nav.Link></li> 

          </i>
          </ul>
         </div>
        </Col>

        <Col  md={3}>
        <div className="espaceicon p-3">
            <SocialMediaLinks />            
      </div>
        <div className='buttonfo'>
        <Button variant="primary" className='b1' href='https://wa.link/272i4m'>Hacer Donación</Button>
        </div>
        </Col>

      </Row>
      <div className='text-center footer2 p-3 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Luis Fernando Castillo, 

Simetría A.C. es una organización de la sociedad civil apartidista, sin fines de lucro, dedicada a la investigación y análisis de políticas púbicas. 
      </div>
    </Container>
    </div>
    </section>
  </section>
  



  
  );
}

export default WithHeaderExample;