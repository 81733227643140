// Importa las dependencias necesarias
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Contenido/css/Articulos.css';
// Impotaciones de menu
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet, Link } from "react-router-dom"
import logoss from '../images/logos.jpg'
import { ElfsightWidget } from 'react-elfsight-widget';
import SocialMediaLinks from './iconos';

import Flip from 'react-reveal/Flip';
//Importaciones de footer
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Componente funcional para las cards de texto
const Proyectos = () => {
  return (    
<div>
    <section>
    <Navbar collapseOnSelect expand="lg" className="bg-transparent">
      <Container>
      <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logoss} width="80" height="auto" /></Flip></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">    
                <Nav.Link as={Link} to="/" className='navLinkStyle'>Home</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>Blog</Nav.Link>    
                <Nav.Link as={Link} to="/Cardst" className='navLinkStyle'>Talleres y Cursos</Nav.Link> 
                <Nav.Link as={Link} to="/Articulos" className='navLinkStyle'>Artículos de Opinión</Nav.Link>  
                <Nav.Link as={Link} to="/Proyectos" className='navLinkStyle'>Proyectos</Nav.Link>  
                <Nav.Link as={Link} to="/Contacto" >Contacto</Nav.Link>
                <Nav.Link as={Link} to="/Consejo" className='navLinkStyle'>Consejo Asesor</Nav.Link>                   
            </Nav>
            <Nav className="ms-auto"> 
            <SocialMediaLinks />            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <section>
            <Outlet>


              
            </Outlet>
        </section> 
    </section>
    <div className="d-flex align-items-center justify-content-center text-center">
    <div className="titles2"> 
    <Flip top><h3 className='mx-auto title22'>Proyectos
</h3></Flip> 
    </div>
    </div>
    <ElfsightWidget widgetID="bba725ba-a352-4d66-a87e-5c6a8f9addaa" />

    <section>
    <div className="footerr">
    <Container>
      <Row className='row2'>
        <Col  md={3}>
          <img src={logoss} width="100px" alt="" />
        </Col>
        <Col  md={3}>
         <div>
          <h2 className='h2h'>A PROPÓSITO</h2>
          <p>Fomentamos la igualdad de género, el empoderamiento, la no discriminación y la erradicación de la violencia hacia las mujeres </p>
         </div>
        </Col>
        <Col md={3}>
        <div className='rowesp'>
          <h2 className='h2h'>A EXPLORAR</h2>
          <ul className="listafooter">
          <i className='mx-auto'>
                <li><Nav.Link as={Link} to="/" className='navfo'>Home</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Blog" className='navfo'>Blog</Nav.Link></li>    
                <li><Nav.Link as={Link} to="/Cardst" className='navfo'>Talleres y Cursos</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Articulos" className='navfo'>Artículos de Opinión</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Contacto" className='navfo'>Contacto</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Consejo" className='navfo'>Consejo Asesor</Nav.Link></li> 

          </i>
          </ul>
         </div>
        </Col>

        <Col  md={3}>
        <div className="espaceicon p-3">
            <SocialMediaLinks />            
      </div>
        <div className='buttonfo'>
        <Button variant="primary" className='b1' href='https://wa.link/272i4m'>Hacer Donación</Button>
        </div>
        </Col>

      </Row>
      <div className='text-center footer2 p-3 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Luis Fernando Castillo, 

Simetría A.C. es una organización de la sociedad civil apartidista, sin fines de lucro, dedicada a la investigación y análisis de políticas púbicas. 
      </div>
    </Container>
    </div>
    </section>
    </div>   
    
  );
};

export default Proyectos;
