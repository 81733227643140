import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './Contenido/App'
// import Cards from './Contenido/experimento'
// import Wid from './widgets/WidFace'
// import Prueba from './Contenido/Prueba'
// import Footer from './Contenido/footer';
// import Prueba2 from './Contenido/Prueba2.jsx'





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
    {/* <Cards/>   */}
    {/* <Prueba2/> */}
    {/* <Wid/> */}
    {/* <Prueba/> */}
    {/* <Footer/> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
