import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoss from '../images/logos.jpg'
import Flip from 'react-reveal/Flip';
import '../Contenido/css/home.css'
import './Blog'
import Carousel from 'react-bootstrap/Carousel';
import '../Contenido/css/carousel.css'
import car1 from '../images/1.png'
import car2 from '../images/2.png'
import car3 from '../images/3.png'
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import icon11 from '../images/4.png'
import icon12 from '../images/5.png'
import icon13 from '../images/7.png'
import icon14 from '../images/8.png'
import icon15 from '../images/9.png'
import '../Contenido/css/section1.css'
import Bounce from 'react-reveal/Bounce';
import '../Contenido/css/cards.css'


import './scss/Prueba2.scss';

import logo1 from '../images/2c.png'
import logo2 from '../images/3c.png'
import logo3 from '../images/4c.png'
import logo4 from '../images/5abc.png'
import logo5 from '../images/6c.png'
import logo6 from '../images/7c.png'
import logo7 from '../images/8c.png'
import logo8 from '../images/9c.png'
import logo9 from '../images/10c.png'
import logo10 from '../images/11c.png'
import logo11 from '../images/12c.png'
import logo12 from '../images/13c.png'
import logo13 from '../images/14c.png'
import logo14 from '../images/15c.png'
import logo15 from '../images/16c.png'
import logo16 from '../images/17c.png'
import logo17 from '../images/18c.png'
import '../widgets/WidFace.css'


// import '../Java/mamon';
import '../Contenido/css/Prueba.css';
import '../Contenido/css/mamon.scss';
import ff from'../images/f1.jpg'
import fa from'../images/f2.jpg'
import fb from'../images/f3.jpg'


// import { BsTwitter } from "react-icons/bs";
// import { FaFacebookF} from "react-icons/fa";
// import {FaLinkedinIn} from "react-icons/fa";

import  './css/footer.css';
import { Outlet, Link } from "react-router-dom"



// import Card from 'react-bootstrap/Card';
// import './css/styles.css';
// // import Container from 'react-bootstrap/Container';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import { Pagination, Autoplay } from 'swiper/modules';

// // PDFS DE NOVEDADES

// import pdf from './Pdf/1.pdf';
 import pdfa from './Pdf/2.pdf';
// import pdfb from './Pdf/3.pdf';
// import pdfc from './Pdf/4.pdf';
// // IMAGENES DE NOVEDADES
// import ina from '../images/ina.png';
// import inb from '../images/inb.png';
// import inc from '../images/inc.png';
// import ind from '../images/ind.png';
// IMAGENES E IMPORT PARALLAX
import { Parallax } from 'react-parallax';
import fondpara from '../images/fondpara.jpg';
//Imagenees de incidencia
import derecha from '../images/derecha.jpg'
import izquierda from '../images/izquierda.jpg'
import nove1 from '../images/nove1.jpg'
import nove2 from '../images/nove2.jpg'
import nove3 from '../images/nove3.jpg'
//COOOKIES
import React, { useState } from 'react';
import { Toast, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import SocialMediaLinks from './iconos';


function Home() {
  const logos = [

    { src: logo1, alt: 'Logo 1' },
    { src: logo2, alt: 'Logo 2' },
    { src: logo3, alt: 'Logo 2' },
    { src: logo4, alt: 'Logo 2' },
    { src: logo5, alt: 'Logo 2' },
    { src: logo6, alt: 'Logo 2' },
    { src: logo7, alt: 'Logo 2' },
    { src: logo8, alt: 'Logo 2' },
    { src: logo9, alt: 'Logo 2' },
    { src: logo10, alt: 'Logo 2' },
    { src: logo11, alt: 'Logo 2' },
    { src: logo12, alt: 'Logo 2' },
    { src: logo13, alt: 'Logo 2' },
    { src: logo14, alt: 'Logo 2' },
    { src: logo15, alt: 'Logo 2' },
    { src: logo16, alt: 'Logo 2' },
    { src: logo17, alt: 'Logo 2' },
 


    // Agrega más logos según sea necesario
  ];
  const [showBanner, setShowBanner] = useState(true);

  const handleAcceptCookies = () => {
    // Establecer la cookie de aceptación con una duración de 365 días
    Cookies.set('cookieAccepted', 'true', { expires: 365 });
    setShowBanner(false);
  };
  
  
  return (
    
// /////////////////////////MENU////////////////////////////////////

  <div>
     <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '10px', zIndex: 1000 }}>
      <Toast show={showBanner} onClose={() => setShowBanner(false)}>
        <Toast.Header>
          <strong className="me-auto">Mensaje de Cookies</strong>
        </Toast.Header>
        <Toast.Body>
          Este sitio web utiliza cookies para mejorar la experiencia del usuario.
          <Button variant="success" onClick={handleAcceptCookies}>
            Aceptar cookies
          </Button>
        </Toast.Body>
      </Toast>
    </div>
<Navbar collapseOnSelect expand="lg" className="bg-transparent">
      <Container>
      <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logoss} width="80" height="auto" /></Flip></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">    
                <Nav.Link as={Link} to="/" className='navLinkStyle'>Home</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>Blog</Nav.Link>    
                <Nav.Link as={Link} to="/Cardst" className='navLinkStyle'>Talleres y Cursos</Nav.Link> 
                <Nav.Link as={Link} to="/Articulos" className='navLinkStyle'>Artículos de Opinión</Nav.Link>  
                <Nav.Link as={Link} to="/Proyectos" className='navLinkStyle'>Proyectos</Nav.Link>  
                <Nav.Link as={Link} to="/Contacto" >Contacto</Nav.Link>
                <Nav.Link as={Link} to="/Consejo" className='navLinkStyle'>Consejo Asesor</Nav.Link>                   
            </Nav>
            <Nav className="ms-auto"> 
            <SocialMediaLinks />            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <section>
            <Outlet>


              
            </Outlet>
        </section> 
{/* //////////////////////SECTION CAROUSEL ////////////////////////// */}
    <section>
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={car1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h1>Bienvenido a Simetría</h1>
          <p>Construyendo la Igualdad de Género</p>
          <div className="buttons">
          <Button className="b1" href="https://wa.link/xq4tdh">Contáctanos</Button> 
          <Button className="b2" href="https://wa.link/xq4tdh">Donaciones</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={car2}
          alt="Second slide"
        />
        <Carousel.Caption>
        <h2>Trabajamos para Disminuir la Violencia de Género</h2>
        <div className="buttons">
        <Button className="b1" href="https://wa.link/xq4tdh">Contáctanos</Button> 
          <Button className="b2" href="https://wa.link/xq4tdh">Donaciones</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={car3}
          alt="Third slide"
        />
        <Carousel.Caption>
        <h2>Fomentamos el Empoderamiento y Autonomía de las Mujeres</h2>
        <div className="buttons">
        <Button className="b1" href="https://wa.link/xq4tdh">Contáctanos</Button> 
          <Button className="b2" href="https://wa.link/xq4tdh">Donaciones</Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>
    {/* //////////////////////////////////SECTION NOSTROS ///////////////////// */}
    <section>
 
  <div className='bodyy'>
  <Container>
<div class="arrow arrow--top">
    <svg xmlns="http://www.w3.org/2000/svg" width="270.11" height="649.9" overflow="visible">
     
      <g class="item-to bounce-1">
        <path class="geo-arrow draw-in" d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z" />
      </g>
      <circle class="geo-arrow item-to bounce-2" cx="194.65" cy="69.54" r="7.96" />
      <circle class="geo-arrow draw-in" cx="194.65" cy="39.5" r="7.96" />
      <circle class="geo-arrow item-to bounce-3" cx="194.65" cy="9.46" r="7.96" />
      <g class="geo-arrow item-to bounce-2">
        <path class="st0 draw-in" d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552" />
      </g>
    </svg>
  </div>
  <div class="arrow arrow--bottom">
    <svg xmlns="http://www.w3.org/2000/svg" width="31.35" height="649.9" overflow="visible">
      
      <g class="item-to bounce-1">
        <circle class="geo-arrow item-to bounce-3" cx="15.5" cy="580.36" r="7.96" />
        <circle class="geo-arrow draw-in" cx="15.5" cy="610.4" r="7.96" />
        <circle class="geo-arrow item-to bounce-2" cx="15.5" cy="640.44" r="7.96" />
        <g class="item-to bounce-2">
          <path class="geo-arrow draw-in" d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552" />
        </g>
      </g>
    </svg>
  </div>
  <div class="mainn">
    <div className='titlesss'>
    <h1 className='title222'>Sobre Nosotros</h1>
    <h2 className='title333'>Somos una organización de la sociedad civil, apartidista y sin fines de lucro, que busca contribuir a la construcción de un México más inclusivo y equitativo, con una ciudadanía crítica y participativa e instituciones sólidas, responsables y eficaces.  Esto a partir del análisis y construcción de datos e indicadores, así como del estudio de políticas públicas desde su diseño e implementación, hasta sus resultados e impacto.</h2>   
   
      <svg xmlns="http://www.w3.org/2000/svg" class="dotted-circle" width="352" height="352" overflow="visible">
  <circle cx="176" cy="176" r="174" fill="none" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="12.921,11.9271"/>
</svg>
    </div>
  </div>
  </Container>
  </div>
</section>
{/* /////////////////////////////////Opiniones section //////////////////////////// */}
<section>
  <div>
  <Container className="mt-3">
      <Row className="contenedor">
      <Col xs={12} md={6} className="imagen-con-contorno">
          <img src={izquierda} alt="Imagen izquierda" />
        </Col>
        <Col xs={12} md={6} className="imagen-derecha">
          <img src={derecha} alt="Imagen derecha" />
        </Col>
      </Row>
    </Container>
    </div>
</section>
{/* ////////////////////////////////SERVICIOS ICONOS////////////////////////////// */}
    <section>
      
    <div>
      {/* Opciones para el componente Parallax */}
      <Parallax
        blur={5}
        bgImage={fondpara}
        bgImageAlt="background"
        strength={600}
      >
        <div style={{ height: 'auto' }}>
        <div className='section1'>
    <Container>
      
    <div className="titles"> 
    <Flip top><h3 className='mx-auto title2'>Nuestros Servicios</h3></Flip> 
    <Flip top><h4 className='mx-auto title3'>Desarrollamos análisis cualitativo y cuantitativo de datos
     e información que permita tener un adecuado y verídico entendimiento de la 
     realidad que vive nuestra sociedad. 
</h4>
    </Flip> 
    </div>
      <Row className="row1">
        <Col xs={6} md={4} className="mx-auto colmod">
        <Bounce top><Image className="img1" src={icon11} rounded width="40%" height="auto"/></Bounce>
          <h4 className='texth4'>Capacitamos</h4>
          <p className='textpp'>Transmitimos conocimiento para fortalecer las competencias personales, 
          laborales y profesionales en temas de políticas públicas y género. </p>
        </Col>
        <Col xs={6} md={4} className="mx-auto colmod">
        <Bounce bottom><Image className="img1" src={icon12} rounded width="40%" height="auto"/></Bounce>
        <h4 className='texth4'>Acompañamos</h4>
          <p className='textpp'>Ofrecemos acompañamiento y asesoría para incorporar la perspectiva de género en la planeación,
           funcionamiento, seguimiento y evaluación de los programas, 
           procesos y actividades institucionales.</p>
        </Col>
        <Col xs={6} md={4} className="mx-auto colmod">
          <Bounce top><Image className="img1" src={icon13} rounded width="40%" height="auto"/></Bounce>
          <h4 className='texth4'>Evaluamos</h4>
          <p className='textpp'>Desarrollamos evaluaciones de la perspectiva de género en las políticas públicas y privadas implementadas.</p>
        </Col>

        <Col xs={6} md={4} className="mx-auto colmod">
        <Bounce top><Image className="img1" src={icon14} rounded width="40%" height="auto"/></Bounce>
          <h4 className='texth4'>Diagnósticamos</h4>
          <p className='textpp'>Desarrollamos análisis innovadores que permitan tener un panorama real de la situación actual de la población. </p>
        </Col>

        <Col xs={6} md={4} className="mx-auto colmod">
        <Bounce top><Image className="img1" src={icon15} rounded width="40%" height="auto"/></Bounce>
          <h4 className='texth4'>Construimos</h4>
          <p className='textpp'>Soluciones innovadoras que tengan impacto positivo y medible en el bienestar social. </p>
        </Col>

      </Row>
    </Container>
    </div>
        </div>
      </Parallax>
    </div>
    </section>

{/* /////////////////////// CAROUSEL INIFINITO ///////////////////////// */}

<section>
<Container>
      <Row>
        {logos.map((logo, index) => (
          <Col key={index} xs={6} md={4} lg={3} className="text-center mb-3">
            <img
              src={logo.src}
              alt={logo.alt}
              style={{ width: '100%', filter: 'grayscale(100%)' }}
            />
          </Col>
        ))}
      </Row>
    </Container>
    </section>
{/* /////////////////////////// SECTION NOVEDADES////////////////////////////////// */}

<section>
<Container className="text-center mt-4">
  
      <Row className="justify-content-center mb-4">
        {/* Cuadro 1 */}
        <Col md={4}>
        <div className=" text-center box-container" style={{ height: '300px', margin: '20px 0', position: 'relative' }}>
            <h4 className='box-title'>NOVEDADES</h4>
            <a href={pdfa}  download>
              <img
                src={nove2}
                alt="Imagen 1"
                className="img-fluid"
                style={{ maxHeight: '250px', width: 'auto' }}
              />
            </a>
            <p className="textpp">Descargar PDF</p>
          </div>
        </Col>

        {/* Cuadro 2 */}
        <Col md={4}>
        <div className=" text-center box-container" style={{ height: '300px', margin: '20px 0', position: 'relative' }}>
            <h4 className='box-title'>NEWLETTER</h4>
            <a href="ruta/para/tu/archivo2.pdf" download>
              <img
                src={nove3}
                alt="Imagen 2"
                className="img-fluid"
                style={{ maxHeight: '250px', width: 'auto' }}
              />
            </a>
            <p className="textpp">Descargar PDF</p>
          </div>
        </Col>

        {/* Cuadro 3 */}
        <Col md={4}>
        <div className=" text-center box-container" style={{ height: '300px', margin: '20px 0', position: 'relative' }}>
            <h4 className='box-title'>MULTIMEDIA</h4>
            <a href="ruta/para/tu/archivo3.pdf" download>
              <img
                src={nove1}
                alt="Imagen 3"
                className="img-fluid"
                style={{ maxHeight: '250px', width: 'auto' }}
              />
            </a>
            <p className=""></p>

          </div>
        </Col>
      </Row>

    </Container>
</section>
{/* /////////////////////// CARSD DE PRESENTACION ////////////////// */}
    <section>
    <div className='cardsefec'>
	<div className="titles">
    {/* <Flip top><h2 className='title11'>Construyendo la Igualdad de Género</h2></Flip> */}
    <Flip top><h3 className='mx-auto title2'>Equipo de Investigación</h3></Flip> 
    <Flip top><h4 className='mx-auto title3'>
Somos un grupo de especialistas con formación técnica sólida y amplia experiencia en el diseño, evaluación, análisis e implementación de políticas públicas y la acción gubernamental, así como en estudios de género y de derechos humanos. Además, el equipo de investigación es experto en manejo estadístico de datos y en técnicas geoespaciales.  
</h4>
    </Flip> 
    </div>
    <div class="container">
		<div class="carousel slide" data-bs-ride="carousel" id="carouselExampleSlidesOnly">
			<div class="carousel-inner">
				<div class="carousel-itemm active">
					<div class="row ">
						<div class="col-lg-4">
							<div class="card1">
								<div class="box front">
									<img alt="" src={ff}/>
									<h2>Alicia Santana Cartas</h2>
									<h4>Directora General</h4>
									{/* <p class="socials">
										<i class="fa fa-facebook"></i>
										<i class="fa fa-twitter"></i>
										<i class="fa fa-linkedin"></i>
										<i class="fa fa-youtube"></i>
									</p> */}
								</div>
								<div class="box back">
									<span class="fa fa-quote-left"></span>
									<p>
										
									Especialista en análisis, diseño y evaluación de políticas públicas relacionadas con desarrollo social, la desigualdad y las brechas de género. Analista de bases de datos, ciencia de datos y big data. Experta en diseño de encuestas. Es economista por el ITAM, diplomado en econometría avanzada y tiene una maestría en economía aplicada por la Universidad Autónoma de Barcelona.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="card1">
								<div class="box front">
									<img alt="" src={fa}/>
									<h2>Silke de la Parra</h2>
									<h4>Coordinadora de proyectos</h4>
								</div>
								<div class="box back">
									<span class="fa fa-quote-left"></span>
									<p>
									Maestra en Gobierno y Administración Pública por la Universidad Complutense de Madrid. Con más de 12 años de experiencia en el diseño, implementación y seguimiento de programas, proyectos y acciones encaminados a prevenir la violencia social y el delito. Ha trabajado en organizaciones de la sociedad civil, para USAID, en el sector público para el Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública, entre otras.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="card1">
								<div class="box front">
									<img alt="" src={fb}/>
									<h2>Rocío Chavira</h2>
									<h4>Directora de vinculación</h4>
								</div>
								<div class="box back">
									<span class="fa fa-quote-left"></span>
									<p>
                  Licenciada en Trabajo Social por la UNAM, especialista en coordinación de proyectos que forman parte de la Responsabilidad Social de Empresas y micro negocios, experta en desarrollar y evaluar proyectos sociales de ONG y desarrollo comunitario.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
									</div>
				</div>
			</div>			


		</div>
    </section>

  
    <section>
    <div className="footerr">
    <Container>
      <Row className='row2'>
        <Col  md={3}>
          <img src={logoss} width="100px" alt="" />
        </Col>
        <Col  md={3}>
         <div>
          <h2 className='h2h'>A PROPÓSITO</h2>
          <p>Fomentamos la igualdad de género, el empoderamiento, la no discriminación y la erradicación de la violencia hacia las mujeres </p>
         </div>
        </Col>
        <Col md={3}>
        <div className='rowesp'>
          <h2 className='h2h'>A EXPLORAR</h2>
          <ul className="listafooter">
          <i className='mx-auto'>
                <li><Nav.Link as={Link} to="/" className='navfo'>Home</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Blog" className='navfo'>Blog</Nav.Link></li>    
                <li><Nav.Link as={Link} to="/Cardst" className='navfo'>Talleres y Cursos</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Articulos" className='navfo'>Artículos de Opinión</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Contacto" className='navfo'>Contacto</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Consejo" className='navfo'>Consejo Asesor</Nav.Link></li> 

          </i>
          </ul>
         </div>
        </Col>

        <Col  md={3}>
        <div className="espaceicon p-3">
            <SocialMediaLinks />            
      </div>
        <div className='buttonfo'>
        <Button variant="primary" className='b1' href='https://wa.link/272i4m'>Hacer Donación</Button>
        </div>
        </Col>

      </Row>
      <div className='text-center footer2 p-3 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Luis Fernando Castillo, 

Simetría A.C. es una organización de la sociedad civil apartidista, sin fines de lucro, dedicada a la investigación y análisis de políticas púbicas. 
      </div>
    </Container>
    </div>
    </section>
    
    </div>

    


  );
}

export default Home;



