
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';


//importamos los comp creados
import Home from './Home';
import Blog from './Blog';
import Servicios from './cards';
import Articulos from './Articulos';
import Proyectos from './Proyectos';
import Contacto from  './Contacto';
import Consejo from  './Consejo';


function App() {
  return (
    
    <div className="App">
<BrowserRouter>
<Routes>
  <Route>
    <Route index element={ <Home/> } />
    <Route path='Home' element={ <Home /> } />
    <Route path='Blog' element={ <Blog /> } />
    <Route path='Cardst' element={ <Servicios/> } />
    <Route path='Articulos' element={ <Articulos/> } />
    <Route path='Proyectos' element={ <Proyectos/> } />
    <Route path='Contacto' element={ <Contacto/> } />
    <Route path='Consejo' element={ <Consejo/> } />
    <Route path='*' element={ <Navigate replace to="/"/> }/>
  </Route>
</Routes> 
</BrowserRouter>

    </div>
  );
}

export default App;
