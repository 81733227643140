// Importa las dependencias necesarias
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';
import '../Contenido/css/Articulos.css';
// Impotaciones de menu
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet, Link } from "react-router-dom"
import logoss from '../images/logos.jpg'
import Flip from 'react-reveal/Flip';
import SocialMediaLinks from './iconos';
//Importaciones de footer
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Componente funcional para las cards de texto
const Articulos = () => {
  return (    
<div>

    <section>
<Navbar collapseOnSelect expand="lg" className="bg-transparent">
      <Container>
      <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logoss} width="80" height="auto" /></Flip></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">    
                <Nav.Link as={Link} to="/" className='navLinkStyle'>Home</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>Blog</Nav.Link>    
                <Nav.Link as={Link} to="/Cardst" className='navLinkStyle'>Talleres y Cursos</Nav.Link> 
                <Nav.Link as={Link} to="/Articulos" className='navLinkStyle'>Artículos de Opinión</Nav.Link>  
                <Nav.Link as={Link} to="/Proyectos" className='navLinkStyle'>Proyectos</Nav.Link>  
                <Nav.Link as={Link} to="/Contacto" >Contacto</Nav.Link>
                <Nav.Link as={Link} to="/Consejo" className='navLinkStyle'>Consejo Asesor</Nav.Link>                   
            </Nav>
            <Nav className="ms-auto"> 
            <SocialMediaLinks />            
          </Nav>
        </Navbar.Collapse>
        

      </Container>
    </Navbar>
    <section>
            <Outlet>


              
            </Outlet>
        </section> 
    </section>

    <div className="d-flex align-items-center justify-content-center text-center">
    <div className="titles2"> 
    <Flip top><h3 className='mx-auto title22'>Artículos de Opinión
</h3></Flip> 
    </div>
    </div>
<div className="container mt-5">   

<div className="row">
        <div className="col-md-4">
          <Card>
            <Card.Body className='articles'>
              <Card.Title className='tiart'>CRIMEN Y POBREZA </Card.Title>
              <Card.Text className='texart'>
              Toda política pública bien diseñada y evaluada que busque reducir 
              los niveles de pobreza en el país debe ser aplaudida, pero la política social 
              no puede ser la única forma de enfrentar al crimen.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body className='articles'>
              <Card.Title className='tiart'>BIDEN: EL CAMBIO ESPERADO POR MUCHAS MUJERES </Card.Title>
              <Card.Text className='texart'>
              Biden ha puesto especial atención en implementar acciones que empiecen a equilibrar 
              la balanza y se ha comprometido en llevar a cabo un plan agresivo e 
              integral para promover la seguridad económica y física de las mujeres y 
              garantizar que éstas puedan ejercer plenamente sus derechos.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body className='articles'>
              <Card.Title className='tiart'>INCLUSIÓN DE MÁS MUJERES EN EL MERCADO LABORAL Y MÁS HOMBRES RESPONSABLES DE LAS TAREAS DOMÉSTICAS. </Card.Title>
              <Card.Text className='texart'>
              El PIB podría incrementar 34 % en 10 años si mujeres y hombres tuvieran la
               misma tasa de participación laboral, trabajaran la misma cantidad de horas
                y presentaran idénticos índices de productividad.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body className='articles'>
              <Card.Title className='tiart'>LAS MUJERES CON UN TRABAJO INFORMAL EN TIEMPOS DE COVID</Card.Title>
              <Card.Text className='texart'>
              La vulnerabilidad de las personas que laboran en la informalidad podría tener un 
              impacto negativo durante el segundo semestre del año ante un posible rebrote de 
              COVID-19, particularmente el de las mujeres, que en términos generales han sido 
              las más afectadas.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>MUNICIPIOS: VÍCTIMAS INVISIBLES DEL CORONAVIRUS</Card.Title>
              <Card.Text className='texart'>
              La crisis sanitaria está afectando a los municipios no sólo por la caída de sus
               ingresos propios, como consecuencia de una menor actividad económica, sino también
                por la disminución de las transferencias intergubernamentales que reciben, 
                las cuales han descendido a raíz de los menores ingresos fiscales de la federación.
              </Card.Text>
            </Card.Body>
          </Card>

          
        </div>

        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title className='tiart'>Y SIN EMBARGO SE REQUIERE LA PERSPECTIVA DE GÉNERO</Card.Title>
              <Card.Text className='texart'>
              Jóvenes Construyendo el Futuro debe considerar las desventajas adicionales 
              que enfrentan las mujeres jóvenes que no participan en el programa, por
               realizar trabajo del hogar y de cuidados no remunerados.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>EL PROIGUALDAD UNA OPORTUNIDAD QUE NO HA SIDO SUFICIENTEMENTE APROVECHADA</Card.Title>
              <Card.Text className='texart'>
              La principal carencia del Proigualdad 2020-2024 es que mientras se reconocen objetivos
               importantes como la reducción del embarazo en adolescentes, la participación de las mujeres 
               en política, la generación de un sistema de cuidados, o bien, la reducción de la violencia
                contra las mujeres, las estrategias para alcanzar dichos objetivos no se encuentran especificadas en acciones e indicadores concretos.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>LA OTRA PANDEMIA, LA QUE EL GOBIERNO NO QUIERE VER</Card.Title>
              <Card.Text className='texart'>
              El gobierno mexicano no ha tomado con seriedad la violencia contra las mujeres 
              ante la agudización del problema con el confinamiento, pues no hay un giro importante 
              en la estrategia: ni mayores recursos ni programas dedicados a combatir un problema que 
              afecta a miles de mujeres en nuestro país.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>¿QUIÉNES FUERON LOS VERDADEROS ENEMIGOS DE LOS MEXICANOS EN EL 2020?</Card.Title>
              <Card.Text className='texart'>
              Esta crisis sanitaria también cobró otra víctima: el frágil sistema de salud público, que simplemente descuidó la atención de otro 
              tipo de enfermedades mortales, con consecuencias igual de letales.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4">
          <Card>
            <Card.Body>
              <Card.Title className='tiart'>GASTO PÚBLICO SIN PERSPECTIVA DE GÉNERO: FUGA DE CALIDAD EN EL GASTO</Card.Title>
              <Card.Text className='texart'>
              Los gobiernos en México presumen ser aliados de las luchas de las mujeres por 
              asignar dinero a programas dirigidos a ellas. Pero destinar unos cuantos pesos 
              no es suficiente cuando tenemos herramientas para considerar a las mujeres en los
               resultados e impactos de cada peso público que se invierte.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>MUJERES PARA TOMAR DECISIONES</Card.Title>
              <Card.Text className='texart'>
              Al proveer evidencia sobre la calidad de su trabajo, las mujeres en posiciones 
              de toma de decisión reducen el sesgo antimujer que puede existir entre los votantes,
               ya sea por cuestiones de discriminación o por cuestiones de falta de información 
               sobre su capacidad de gobernar.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className='tiart'>PRESUPUESTO 2021 ¿QUÉ TANTO GASTAMOS EN LAS MUJERES?</Card.Title>
              <Card.Text className='texart'>
              Al revisar las reglas de operación y las matrices de resultados de 14 de los programas 
              insignia incluidos en el Anexo 13, se puede concluir que ninguno de éstos tiene como 
              objetivo —ni general ni específico— avanzar en la igualdad entre mujeres y hombres.
              </Card.Text>
            </Card.Body>
          </Card>

          
          <Card>
            <Card.Body>
              <Card.Title className='tiart'>GASTO GUBERNAMENTAL EN IGUALDAD DE GÉNERO: ENTRE EL DISCURSO Y LA REALIDAD </Card.Title>
              <Card.Text className='texart'>
              En el tercer semestre del año los programas de apoyo a las mujeres han sufrido 
              retrasos en el gasto, alarmantes sobre todo ante la ola de violencia y la elevada 
              carga en las labores de cuidados y del hogar.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>



    <section>
    <div className="footerr">
    <Container>
      <Row className='row2'>
        <Col  md={3}>
          <img src={logoss} width="100px" alt="" />
        </Col>
        <Col  md={3}>
         <div>
          <h2 className='h2h'>A PROPÓSITO</h2>
          <p>Fomentamos la igualdad de género, el empoderamiento, la no discriminación y la erradicación de la violencia hacia las mujeres </p>
         </div>
        </Col>
        <Col md={3}>
        <div className='rowesp'>
          <h2 className='h2h'>A EXPLORAR</h2>
          <ul className="listafooter">
          <i className='mx-auto'>
                <li><Nav.Link as={Link} to="/" className='navfo'>Home</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Blog" className='navfo'>Blog</Nav.Link></li>    
                <li><Nav.Link as={Link} to="/Cardst" className='navfo'>Talleres y Cursos</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Articulos" className='navfo'>Artículos de Opinión</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Contacto" className='navfo'>Contacto</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Consejo" className='navfo'>Consejo Asesor</Nav.Link></li> 

          </i>
          </ul>
         </div>
        </Col>

        <Col  md={3}>
        <div className="espaceicon p-3">
            <SocialMediaLinks />            
      </div>
        <div className='buttonfo'>
        <Button variant="primary" className='b1' href='https://wa.link/272i4m'>Hacer Donación</Button>
        </div>
        </Col>

      </Row>
      <div className='text-center footer2 p-3 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Luis Fernando Castillo, 

Simetría A.C. es una organización de la sociedad civil apartidista, sin fines de lucro, dedicada a la investigación y análisis de políticas púbicas. 
      </div>
    </Container>
    </div>
    </section>
    </div>   
    
  );
};

export default Articulos;
