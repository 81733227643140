
import '../Contenido/css/cards.css'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import a from '../images/1aa.png'
import b from '../images/2cc.png'
import c from '../images/3cc.png'
import d from '../images/4cc.png'
import e from '../images/5cc.png'
import f from '../images/6cc.png'
import g from '../images/7cc.png'
import h from '../images/8cc.png'
import i from '../images/9cc.png'
import j from '../images/10cc.png'
import k from '../images/11cc.png'
import l from '../images/12cc.png'
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';


import { Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Link } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../Contenido/css/home.css'
import logoss from '../images/logos.jpg'


import Button from 'react-bootstrap/Button';
import  './css/footer.css';

import React from 'react';
import SocialMediaLinks from './iconos';

function Cardst() {
  return (
    
   
    <div>

<Navbar collapseOnSelect expand="lg" className="bg-transparent">
      <Container>
      <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logoss} width="80" height="auto" /></Flip></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">    
                <Nav.Link as={Link} to="/" className='navLinkStyle'>Home</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>Blog</Nav.Link>    
                <Nav.Link as={Link} to="/Cardst" className='navLinkStyle'>Talleres y Cursos</Nav.Link> 
                <Nav.Link as={Link} to="/Articulos" className='navLinkStyle'>Artículos de Opinión</Nav.Link>  
                <Nav.Link as={Link} to="/Proyectos" className='navLinkStyle'>Proyectos</Nav.Link>  
                <Nav.Link as={Link} to="/Contacto" >Contacto</Nav.Link>
                <Nav.Link as={Link} to="/Consejo" className='navLinkStyle'>Consejo Asesor</Nav.Link>                   
            </Nav>
            <Nav className="ms-auto"> 
            <SocialMediaLinks />            
          </Nav>
        </Navbar.Collapse>
        

      </Container>
    </Navbar>
    <section>
            <Outlet>


              
            </Outlet>
        </section> 
     
    <div className="cards mx-auto">
    <div className="titles2"> 
    <Flip top><h3 className='mx-auto title22'> Talleres y Capacitaciones</h3></Flip> 
    <Flip top><h4 className='mx-auto title33'>Desarrollamos análisis cualitativo y cuantitativo de datos
     e información que permita tener un adecuado y verídico entendimiento de la 
     realidad que vive nuestra sociedad.</h4>
    </Flip> 
    </div>
    <Flip top><h3 className='mx-auto title22 m-4'>Gobierno</h3></Flip> 
    

    <Row xs={1} md={4} className="g-4 m-0">
    <Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={a}/>   
            <Card.Body>
              <Card.Title>Fundamentos</Card.Title>
              <Card.Text>
              Elementos sustanciales y marco lógico de políticas públicas con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={b}/>   
            <Card.Body>
              <Card.Title>Diseño y análisis</Card.Title>
              <Card.Text>
              Un presupuesto para la igualdad entre hombres y mujeres.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={c}/>   
            <Card.Body>
              <Card.Title>Planeación</Card.Title>
              <Card.Text>
              Análisis y monitoreo del presupuesto público
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={d}/>   
            <Card.Body>
              <Card.Title>Evaluación </Card.Title>
              <Card.Text>
              Políticas públicas con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
    </Row>



    <Row xs={1} md={4} className="g-4 m-0">
    <Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={e}/>   
            <Card.Body>
              <Card.Title>Acciones para prevenir</Card.Title>
              <Card.Text>
              Atender el hostigamiento y acoso sexual.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={f}/>   
            <Card.Body>
              <Card.Title>Curso de metodologías </Card.Title>
              <Card.Text>
              Para la evaluación de políticas públicas, incluyendo la evaluación 
              con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={g}/>   
            <Card.Body>
              <Card.Title>Diseño de un programa</Card.Title>
              <Card.Text>
              Gestión de Recursos humanos con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={h}/>   
            <Card.Body>
              <Card.Title>Comunicación Social</Card.Title>
              <Card.Text>
              Difusión con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
    </Row>



    <Flip top><h3 className='mx-auto title22 m-4'>Empresas</h3></Flip> 

    <Row xs={1} md={4} className="g-4 m-0">
    <Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={i}/>   
            <Card.Body>
              <Card.Title>El mercado laboral</Card.Title>
              <Card.Text>
              Discriminación y roles de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={j}/>   
            <Card.Body>
              <Card.Title>Comunicación social</Card.Title>
              <Card.Text>
              Difusión con perspectiva de género.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade bottom>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={k}/>   
            <Card.Body>
              <Card.Title>La conciliación</Card.Title>
              <Card.Text>
              La vida laboral con la vida familiar.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
<Fade top>
        <Col>
          <Card className="cards1 mx-auto hoverImageWrapper"> 
            <Card.Img variant="top" className="hoverImage" src={l}/>   
            <Card.Body>
              <Card.Title>Aplicación de la perspectiva</Card.Title>
              <Card.Text>
              Género en el ámbito administrativo.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
</Fade>
    </Row>
    </div>
    
    <section>
    <div className="footerr">
    <Container>
      <Row className='row2'>
        <Col  md={3}>
          <img src={logoss} width="100px" alt="" />
        </Col>
        <Col  md={3}>
         <div>
          <h2 className='h2h'>A PROPÓSITO</h2>
          <p>Fomentamos la igualdad de género, el empoderamiento, la no discriminación y la erradicación de la violencia hacia las mujeres </p>
         </div>
        </Col>
        <Col md={3}>
        <div className='rowesp'>
          <h2 className='h2h'>A EXPLORAR</h2>
          <ul className="listafooter">
          <i className='mx-auto'>
                <li><Nav.Link as={Link} to="/" className='navfo'>Home</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Blog" className='navfo'>Blog</Nav.Link></li>    
                <li><Nav.Link as={Link} to="/Cardst" className='navfo'>Talleres y Cursos</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Articulos" className='navfo'>Artículos de Opinión</Nav.Link></li>
                <li><Nav.Link as={Link} to="/Contacto" className='navfo'>Contacto</Nav.Link></li> 
                <li><Nav.Link as={Link} to="/Consejo" className='navfo'>Consejo Asesor</Nav.Link></li> 

          </i>
          </ul>
         </div>
        </Col>

        <Col  md={3}>
        <div className="espaceicon p-3">
            <SocialMediaLinks />            
      </div>
        <div className='buttonfo'>
        <Button variant="primary" className='b1' href='https://wa.link/272i4m'>Hacer Donación</Button>
        </div>
        </Col>

      </Row>
      <div className='text-center footer2 p-3 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Luis Fernando Castillo, 

Simetría A.C. es una organización de la sociedad civil apartidista, sin fines de lucro, dedicada a la investigación y análisis de políticas púbicas. 
      </div>
    </Container>
    </div>
    </section>
    </div>
  );
}

export default Cardst;