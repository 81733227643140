// SocialMediaLinks.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import './Home';  // Importa el archivo de estilos

const SocialMediaLinks = () => {
  return (
    <Container className="social-media-container">
    <Row className="align-items-center">
      <Col md={2} className="social-icon facebook">
        <a href="https://www.facebook.com/simetriaorg?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <FaFacebook size={25} color="grey" />
        </a>
      </Col>
      <Col md={2} className="social-icon instagram">
        <a href="https://instagram.com/simetriaorg?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={25} color="grey" />
        </a>
      </Col>
      <Col md={2} className="social-icon twitter">
        <a href="https://twitter.com/simetriamx" target="_blank" rel="noopener noreferrer">
          <FaTwitter size={25} color="grey" />
        </a>
      </Col>
      <Col md={2} className="social-icon linkedin">
        <a href="https://www.linkedin.com/company/simetria-ac/?originalSubdomain=mx" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={25} color="grey" />
        </a>
      </Col>
      
    </Row>
  </Container>
  );
};

export default SocialMediaLinks;
